import Vue from 'vue'
import svgIcon from '@/components/common/svgIcon'

// icons图标自动加载

// 全局注册svg-icon组件
Vue.component('svg-icon', svgIcon)

const req = require.context('./svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
