<template>
  <div id="app">
    <keep-alive :include="['credit']">
      <routerView v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  mounted() {
    this.initLocale()
  },
  methods: {
    initLocale() {
      let locale = ''
      /**
       *  zh: 中文
       *  en: 英文
       *  id: 印尼
       *  vi: 越南
       *  hi: 印地
       */
      switch (navigator.language) {
        case 'zh-CN':
          locale = 'zh'
          break
        case 'en-US':
          locale = 'en'
          break
        // case 'vi-VN': // 越南
        // case 'vi':
        //   locale = 'vi'
        //   break
        case 'id-ID':
        case 'id':
          locale = 'id'
          break
        // case 'hi-IN': // 印地
        // case 'hi':
        //   locale = 'hi'
        //   break
        // case 'th': // 泰国
        //   locale = 'th'
        //   break
        default:
          locale = 'en'
          break
      }
      this.$root.$i18n.locale = locale
    }
  }
}
</script>
