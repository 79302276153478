<template>
  <div class="sign-wrap">
    <sign-header></sign-header>
    <div class="sign-main">
      <div class="sign-box">
        <div v-if="isSuccess">
          <svg-icon icon-class="success" :style="{fill: themeColor}"></svg-icon>
          <p class="tips">{{$t('sign.successTip', {num: curCount})}}</p>
        </div>
        <div v-else>
          <svg-icon icon-class="fail" :style="{fill: themeColor}"></svg-icon>
          <p class="tips">{{message}}</p>
        </div>
      </div>
      <div class="sign-btns">
        <button @click="handleNextStep" class="next-step" v-if="isSuccess || (!isSuccess && !isCanRetry && failReturnUrl)" :style="{background: themeColor}">{{$t('sign.nextStep')}}</button>
        <button @click="handleRetry" class="retry" v-if="!isSuccess && isCanRetry" :style="{background: themeColor}">{{$t('sign.retry')}}</button>
        <p class="retry-tips" v-if="!isSuccess && isCanRetry">{{$t('sign.retryTip', {num: tryCount})}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import signHeader from '@/components/esign/header'
export default {
  data () {
    return {
      countDownTimer: null,
      curCount: 1,
      count: 3,
    }
  },
  components: {
    signHeader
  },
  computed: {
    failReturnUrl() {
      return this.$route.query.failReturnUrl || ''
    },
    successReturnUrl() {
      return this.$route.query.successReturnUrl || ''
    },
    message() {
      return this.$route.query.message
    },
    tryCount() {
      return this.$route.query.tryCount
    },
    themeColor() {
      return sessionStorage.getItem('themeColor')
    },
    isSuccess() {
      return this.$route.query.result === 'SUCCESS'
    },
    isCanRetry() {
      return !this.isSuccess && this.tryCount > 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isSuccess && this.successReturnUrl) {
        this.countDownPromise()
      }
    })
  },
  methods: {
    handleNextStep() {
      if (this.isSuccess && this.successReturnUrl) {
        this.nextSuccessStep()
      }
      if (!this.isSuccess && this.failReturnUrl) {
        this.nextFailStep()
      }
    },
    handleRetry() {
      history.go(-1)
    },
    countDownPromise() {
      this.resetCountDown()
      this.countDownTimer = setInterval(() => {
        this.curCount --
        if (this.curCount <= 1) {
          clearInterval(this.countDownTimer)
          this.nextSuccessStep()
        }
      }, 1000)
    },
    resetCountDown() {
      this.curCount = this.count
      clearInterval(this.countDownTimer)
      this.countDownTimer = null
    },
    nextSuccessStep() {
      window.location.href = `${this.successReturnUrl}?successCode=${this.$route.query.result}&version=esign`
    },
    nextFailStep() {
      window.location.href = `${this.failReturnUrl}?failCode=${this.$route.query.result}&message=${this.message}&version=esign`
    }
  }
}
</script>
<style lang="scss" scoped>
  .sign-wrap {
    height: 100%;
    .sign-main {
      padding: 0 .48rem .16rem;
      height: calc(100% - 1.4rem);
      display: flex;
      flex-direction: column;
    }
  }
  .sign-box {
    width: 100%;
    flex: 1;
    border-radius: .16rem;
    text-align: center;
    position: relative;
    color: #000;
    svg {
      width: 2.56rem;
      height: 2.56rem;
    }
    & > div {
      padding-top: 1.6rem
    }
    .tips {
      font-size: .3rem;
      padding-top: .24rem
    }
  }
  .sign-btns {
    padding: .2rem 0 0;
    text-align: center;
    button{
      width: 100%;
      height: .84rem;
      background: #38a822;
      border: 0;
      border-radius: .12rem;
      color: #fff;
      font-size: .29rem;
      &.next-step {
        margin-bottom: 1.54rem
      }
    }
    .retry-tips {
      color: #666;
      line-height: .56rem;
      padding-top: .24rem;
      margin-bottom: .72rem
    }
  }
</style>