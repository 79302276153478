<template>
  <div class="visa_wrap">

    <div class="sdk_element" id="sdk_element"></div>
  </div>
</template>
<script>
import { sync } from '@/api/modules/visa'
export default {
  data () {
    return {
      fullscreenLoading: null,
    }
  },
  components: {},
  mounted() {
    this.initFun()
  },
  methods: {
    initFun() {
      document.cookie = `accessToken=${this.token};path=/ ;secure ;`
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://sdk.vuis.visa.com/build/obo.min.js'
      document.body.appendChild(script)

      window.addEventListener("message", (event) => {
        try {
          let eventResult = event.data
          let message = ''
          let code = ''
          console.log(eventResult)
          if (eventResult.type === "obo.success") {
            const referenceId = eventResult.data && eventResult.data.referenceId ? eventResult.data.referenceId : null
            const code = 'SUCCESS'
            message = eventResult.data && eventResult.data.referenceId ? '' : 'code is success but no referenceId'
            // this.openFullScreen2()
            this._setCirReportExp(referenceId, code, message)
          } else if (eventResult.type === "obo.error") {
            message = eventResult.payload && eventResult.payload.ErrorMessage && eventResult.payload.ErrorMessage.length > 0 ? eventResult.payload.ErrorMessage : ''
            code = eventResult.payload && eventResult.payload.ErrorType && eventResult.payload.ErrorType.length > 0 ? eventResult.payload.ErrorType : 'ERROR'
            this._setCirReportExp(null, code, message)
          }
        } catch(error) {
          console.log(error)
        }
      })
      setTimeout(() => {
        window.EntryPoint.init()
        this.confirmFlag(8)
      }, 1000)
    },
    // 8秒后查询页面是否有iframe 且 有src
    confirmFlag(timer) {
      setTimeout(() => {
        const iframeTag = document.getElementsByTagName('iframe')
        if (!(iframeTag && iframeTag.length > 0 && iframeTag[0].src && (iframeTag[0]).src.length > 0)) {
          this._setCirReportExp(null, "ERROR", "No link found")
        }
      }, timer * 1000)
    },
    // loading
    openFullScreen2() {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 保存id获取url
    async _setCirReportExp(referenceId, requestCode, requestMessage) {
      try{
        const params = {
          txTransactionId: this.txId,
          referenceId: referenceId || null,
          requestCode: requestCode || '',
          requestMessage: requestMessage || ''
        }
        const { data } = await sync(params)
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          const redirectUrl = data.data && data.data.redirectUrl ? data.data.redirectUrl : ''
          if (redirectUrl && redirectUrl.length > 0) {
            this.openRedirectUrl(redirectUrl, this.txId, referenceId || null)
          } else {
            this.$message({message: 'No RedirectUrl.', type: 'error', customClass: 'message_box'})
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        if (this.fullscreenLoading) {
          this.fullscreenLoading.close()
        }
      }
    },
    // 打开网页
    openRedirectUrl(url, txTransactionId, referenceId) {
      let redirectUrl = url || ''
      redirectUrl = `${redirectUrl}${redirectUrl.indexOf("?") !== -1 ? '&' : '?'}txTransactionId=${txTransactionId}&referenceId=${referenceId}`
      // redirectUrl = `${redirectUrl}${redirectUrl.indexOf("?") !== -1 ? '&' : '?'}txTransactionId=${txTransactionId}${referenceId ? '&referenceId=' + referenceId : ''}`
      let tempALink = document.createElement("a")
      tempALink.setAttribute("target", "_top")
      tempALink.setAttribute("id", "openWin")
      tempALink.setAttribute("href", redirectUrl)
      if(!document.getElementById('openWin')) document.body.appendChild(tempALink);
      document.getElementById("openWin").click()
      document.body.removeChild(tempALink) 
    }
  },
  computed: {
    token() {
      return this.$route.query.token
    },
    txId() {
      return this.$route.query.transactionId || ''
    },
  },
}
</script>
