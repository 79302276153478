<template>
  <div class="header">
    <svg-icon icon-class="back" class="back" @click="goBack"></svg-icon>
    {{$t('sign.name')}}
  </div>
</template>
<script>
export default {
  methods: {
    goBack() {
      history.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .header {
    padding: .16rem;
    font-size: .36rem;
    line-height: .67rem;
    padding-bottom: .36rem;
    font-weight: bold;
    color: #000;
    .back {
      max-width: .32rem;
      padding-right: .20rem
    }
  }
</style>