const uuidv1 = require('uuid/v1')
import { eventTracking } from '@/api/modules/eventTrack'

export function getReport() {
  return {
    applicationId: 'H5',
    locale: navigator.language,
    bizType: 'esign-H5',
    info: {
      mobileInfo: {
        userAgent: navigator.userAgent,
      },
      detail: {
        reportUuid: uuidv1(),
      },
    },
    sdkVersion: `${process.env.VERSION}`,
    eventType: 'REPORT',
    eventTimestamp: -1,
    eventCostInMilliSeconds: -1
  }
}

export async function sentEventTrack(report) {
  try {
    const { data: { code } } = await eventTracking(report)
    console.log(code)
  } catch (e) {
    console.log('report error', report, e)
  }
}