import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import router from './router/index'
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import './icons/index'
import i18n from '@/i18n'
// import initVconsoleAsync from '@/lib/v-console'
import { getQueryString, isMobile } from '@/lib/utils'
import '@babel/polyfill'
import Es6Promise from 'es6-promise'
import '@/assets/fonts/fonts.css'
import './assets/style/reset.css'

Vue.use(ElementUI, { locale })
require('es6-promise').polyfill()
Es6Promise.polyfill()
// const version = require('../package.json').version
// console.log(version)

// Sentry.init({
//   Vue,
//   dsn: "https://d15c04a278054ab885d6d5cffd8cf5be@vkyc-sentry.advance.ai/7",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });
// 主题色获取
const colorReg = /^([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/
const themeColor = getQueryString('themeColor') || ''
const color = !colorReg.test(themeColor) ? '#30B043' : '#' + themeColor
sessionStorage.setItem('themeColor', color)

// 自动地址匹配
const onlyH5 = ['esign', 'credit', 'devicefinger'] // 只有H5页面的项目
const onlyWeb = [''] // 只有web页面的项目
const bothH5AndWeb = [''] // H5和web都有的项目
router.beforeEach((to, from, next) => {
  const tokenEsign = to.query.bizToken
  const tokenCredit = to.query.token
  if (tokenEsign && tokenEsign.length > 0) {
    sessionStorage.setItem('bizToken', tokenEsign)
  } else if (tokenCredit && tokenCredit.length > 0) {
    sessionStorage.setItem('bizToken', tokenCredit)
  }
  // sessionStorage.setItem('bizToken', to.query.token)
  onlyH5.forEach(item => {
    if (`/${item}/index` === to.path) {
      next({ name: `${item}H5`, query: to.query })
    }
  })
  onlyWeb.forEach(item => {
    if (`/${item}/index` === to.path) {
      next({ name: `${item}Web`, query: to.query })
    }
  })
  bothH5AndWeb.forEach(item => {
    if (`/${item}/index` === to.path) {
      if (isMobile()) {
        next({ name: `${item}H5`, query: to.query })
      } else {
        next({ name: `${item}Web`, query: to.query })
      }
    }
  })
  next()
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

// if (process.env.VUE_APP_CONSOLE === 'Yes') {
//   initVconsoleAsync()
//   window.console.log = window.console.debug;  
// }
