import * as Sentry from '@sentry/browser'

function setExtraUserInfo(scope) {
  let user = JSON.parse(sessionStorage.getItem('user'))
  scope.setExtra('userInfo', {
    user: user.username,
    id: user.id
  })
}

export function sendDataSentry(data, type) {
  Sentry.withScope((scope) => {
    scope.setTag('error.type', type)
    scope.setTag('host', location.host)
    scope.setExtra('response', data)
    setExtraUserInfo(scope)
    Sentry.captureException(new Error(type))
  })
}

export function sendTestEvent() {
  Sentry.captureEvent({
    extra: { test: 'hello 哈哈哈', env: process.env.MODE },
    message: 'test-' + new Date(),
    level: Sentry.Severity.Error
  })
}

export function sendTestSentry(data, type) {
  Sentry.withScope((scope) => {
    scope.setTag('type', type)
    scope.setTag('host', location.host)
    scope.setExtra('data', data)
    Sentry.captureEvent({ message: type + '_' + new Date() })
  })
}
