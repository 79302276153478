export const getQueryString = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

export const isMobile = () => {
  let userAgent = navigator.userAgent
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  return Agents.some(i => {
    return userAgent.includes(i)
  })
}

const generBase64Trans = (base64Str) => {
  let [base64head, base64Body] = base64Str.split(',');
  let strArr = base64head.match(/:(.*?);/);
  let mime = strArr ? strArr[1] : '';
  let str = atob(base64Body);
  let n = str.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = str.charCodeAt(n);
  }
  return { u8arr, mime };
};

export const base64ToFile = (base64Str) => {
  let { u8arr, mime } = generBase64Trans(base64Str);
  return new File([u8arr], String(new Date()), { type: mime });
};

export const sortBankList = (propertyName) => {
  return function sortList(object1, object2) {
    const value1 = object1[propertyName].toUpperCase( )
    const value2 = object2[propertyName].toUpperCase( )
    if (value2 < value1) {
      return 1;
    }
    if (value2 > value1) {
      return -1;
    }
    return 0;
  }
}
