export const creditRouter = [
  {
    path: `/credit/h5/index`,
    name: 'credit',
    component: () => import('@/views/credit/h5/index.vue'),
    meta: {
			keepAlive: true		//缓存
		}
  },
  {
    path: '/credit/h5/creditValidation',
    name: 'creditValidation',
    component: () => import('@/views/credit/h5/validation.vue'),
  }
]