import Vue from 'vue'
import Router from 'vue-router'
import { esignRouter } from './esign'
import { creditRouter } from './credit'
import { devicefingerRouter } from './devicefinger'
import { visaRouter } from './visa'
import { employmentCheckRouter } from './employmentCheck'
// import { iframeTestRouter } from './iframeTest'

Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [
    ...esignRouter,
    ...creditRouter,
    ...devicefingerRouter,
    ...visaRouter,
    ...employmentCheckRouter
  ],
})
