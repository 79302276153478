<template>
  <div class="sign-wrap">
    <sign-header></sign-header>
    <div class="sign-main">
      <div class="sign-box" ref="signBox">
        <sign-board
          ref="esign"
          v-if="isRender"
          :isCrop="isCrop"
          :height="height"
          :width="width"
          :lineWidth="lineWidth" 
          :lineColor="lineColor" 
          :bgColor.sync="bgColor"
          @drawChange="drawChange"
        />
      </div>
      <div class="sign-btns">
        <button :disabled="!isDraw" @click="handleGenerate" :class="isDraw ? '' : 'btn-disabled'" :style="{background: themeColor}">{{$t('sign.save')}}</button>
        <button :disabled="!isDraw" @click="handleReset" :class="isDraw ? '' : 'btn-disabled'" :style="{borderColor: themeColor, color: themeColor}">{{$t('sign.delete')}}</button> 
      </div>
    </div>
  </div>
</template>
<script>
import { sendTestSentry } from '@/lib/sentry'
import signHeader from '@/components/esign/header'
import signBoard from '@/components/esign/signBoard'
export default {
  data () {
    return {
      isRender: false,
      lineWidth: 3,
      lineColor: '#000',
      height: 300,
      width: 400,
      bgColor: '',
      resultImg: '',
      isCrop: true,
      isDraw: false
    }
  },
  components: {
    signHeader,
    signBoard
  },
  mounted() {
    console.log(document.body.clientHeight)
    this.$nextTick(() => {
      this.height = this.$refs.signBox.offsetHeight
      this.width = this.$refs.signBox.offsetWidth
      this.isRender = true
      console.log(this.$refs.signBox.offsetHeight)
    })
  },
  computed: {
    themeColor() {
      return sessionStorage.getItem('themeColor')
    },
  },
  methods: {
    drawChange(val) {
      this.isDraw = val
    },
    handleReset () {
      this.$refs.esign.reset()
      sendTestSentry({
        test: 1
      }, 'test')
    },
    handleGenerate () {
      this.$refs.esign.generate().then(res => {
        console.log(res)
        this.resultImg = res
        this.$router.push({
          name: 'esignH5Result',
          query: {
            themeColor: this.themeColor.slice(1),
            result: 'SUCCESS'
          }
        })
      }).catch(err => {
        console.log(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .sign-wrap {
    height: 100%;
    .sign-main {
      padding: 0 .16rem .16rem;
      height: calc(100% - 1.4rem);
      display: flex;
      flex-direction: column;
    }
  }
  .sign-box {
    width: 100%;
    flex: 1;
    background: #F9F9F9;
    border-radius: .16rem;
  }
  .sign-btns {
    padding: .56rem .32rem .42rem;
    text-align: center;
    button{
      width: 100%;
      height: .84rem;
      background: #38a822;
      border: 0;
      border-radius: .12rem;
      color: #fff;
      font-size: .3rem;
      &.btn-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      &:last-child {
        margin-top: .2rem;
        background: none;
        border: 1px solid #38a822;
        color: #38a822
      }
    }
  }
</style>