<template>
  <div :class="[{employmentCheck_dark_wrap: isError}, 'employmentCheck_wrap']">
    <div class="employmentCheck_block_box" v-if="blockBtn"></div>
    <div class="employmentCheck_popover_box" v-if="isError">
      <h3 class="font-bold text-lg">Error</h3>
      <!-- <p class="text-con">Request Error.</p> -->
      <p class="text-con">{{errorMessage}}</p>
      <div class="smile-modal-action">
        <div class="btn" @click="errorBrakeFun">OK!</div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkTransaction } from '@/api/modules/employmentCheck'
import SmileLinkModal from 'SmileLinkModal'
// import './tool/smile.js'
export default {
  data () {
    return {
      fullscreenLoading: null,
      redirectUrl: '',
      isError: false,
      blockBtn: false,
      errorMessage: '',
      codeIsError: false
    }
  },
  components: {},
  mounted() {
    this.initFun()
  },
  methods: {
    loadingHandle() {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: '',
      })
    },
    initFun() {
      // let script = document.createElement('script')
      // script.type = 'text/javascript'
      // script.src = 'https://web.smileapi.io/v2/smile.v2.js'
      // document.body.appendChild(script)
      this._getTransaction()
    },
    proofmarkUrl(redirectUrl, accountId, providerId) {
      redirectUrl = redirectUrl || ''
      if (redirectUrl === '') {
        this.$message({message: 'No RedirectUrl!', type: 'error', customClass: 'message_box'})
      } else {
        this.openRedirectUrl(redirectUrl, accountId, providerId)
      }
    },
    onAccountConnectedHandle(accountId, userId, providerId) {
      this.blockBtn = true
      this.proofmarkUrl(this.redirectUrl, accountId, providerId)
    },
    errorBrakeFun() {
      this.proofmarkUrl(this.redirectUrl)
    },
    smileLinkModalHandle(userToken) {
      /* eslint-disable no-undef */
      const smileLinkModal = new SmileLinkModal({
        /**
          * The Link API URI. Note: Sandbox and Production modes use different API URIs.
          */
          apiHost: 'https://link-sandbox.smileapi.io/v1',
        /**
          * User token(link token) passed from your backend service which is obtained from the Smile API.
          */
          userToken: userToken,
        /**
          * Use provider id to promote certain providers to the top of the list. Example ['upwork', 'freelancer']
          */
          topProviders: [],
        /**
          * Use provider id to show only select providers in the widget. Example ['upwork', 'freelancer']
          */
          providers: [],
        /**
          * Set enableSearchBar to false if you wish to hide the providers search bar.
          * Default: true
          */
          enableSearchBar: true,
        /**
          * Set enableTypeBar to false if you wish to hide the provider types filter.
          * Default: true
          */
          enableTypeBar: true,
        /**
          * Enable or disable file uploads.
          */
          enableUpload: true,           
        /**
          * Set to your company name if you wish your company name to be reflected on the Consent and Login screens.
          * Default: empty
          */
          companyName: "",
        /**
          * Account login callback.
          */
          onAccountCreated: ({
            accountId,
            userId,
            providerId
          }) => {
            console.log('Account created: ', accountId, ' User ID:', userId, ' Provider ID:', providerId)
          },
        /**
          * Account login success callback.
          */
          onAccountConnected: ({
            accountId,
            userId,
            providerId
          }) => {
            this.onAccountConnectedHandle(accountId, userId, providerId)
          },
        /**
          * Account revoke callback.
          */
          onAccountRemoved: ({
            accountId,
            userId,
            providerId
          }) => {
            console.log('Account removed: ', accountId, ' User ID:', userId, ' Provider ID:', providerId)
          },
        /**
          * Token expired callback.
          */
          onTokenExpired: () => {
            console.log('Token expired');
            this.errorBrakeFun()
          },
          /**
           * Smile link SDK close callback.
           */
          onClose: () => {
            console.log('Widget closed')
            this.onCloseHandle()
          },
          /**
           * Account connection error callback.
            Where errorCode is from the account connection errorCode in https://docs.getsmileapi.com/reference/get-account-1.Example.
            ACCOUNT_DISABLED 
            ACCOUNT_INACCESSIBLE 
            ACCOUNT_INCOMPLETE 
            ACCOUNT_LOCKED 
            AUTH_REQUIRED 
            EXPIRED_CREDENTIALS 
            INVALID_ACCOUNT_TYPE 
            INVALID_AUTH 
            INVALID_CREDENTIALS 
            INVALID_MFA MFA_TIMEOUT 
            SERVICE_UNAVAILABLE SYSTEM_ERROR 
            TOS_REQUIRED 
            UNSUPPORTED_AUTH_TYPE 
            UNSUPPORTED_MFA_METHOD
          */
          onAccountError: ({ accountId, userId, providerId, errorCode }) => {
            console.log('Account error: ', accountId, ' User ID:', userId, ' Provider ID:', providerId, 'Error Code:', errorCode)
          },
          /**
           * Uploads submit callback.
           */
          onUploadsCreated: ({ uploads, userId }) => {
            console.log('Uploads: ', uploads, ' User ID:', userId);
          },
      })
      smileLinkModal.open()
    },
    // employmentCheck
    // 获取returnURL
    async _getTransaction() {
      try{
        this.redirectUrl = ''
        const params = {
          transactionId: this.txId || '',
        }
        const { data } = await checkTransaction(params)
        if (data.code === 'SUCCESS') {
          const res = data.data
          const status = res.status || 'deactive'
          this.redirectUrl = res.redirectUrl || ''
          if (status === 'active') {
            this.smileLinkModalHandle(this.userToken)
          } else {
            this.errorMessage = 'Request Error.'
            this.isError = true
            this.codeIsError = true
            // this.proofmarkUrl(this.redirectUrl)
          }
        } else if (data.code === 'REQUEST_ERROR') {
          this.errorMessage = 'Unable to complete your request with required parameters missing or invalid, please try again.'
          this.isError = true
          this.codeIsError = true
        } else {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'}) 
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        if (this.fullscreenLoading) {
          this.fullscreenLoading.close()
        }
      }
    },
    // 打开网页
    openRedirectUrl(url, connectionId, providerId) {
      let redirectUrl = url || ''
      // redirectUrl = `${redirectUrl}${redirectUrl.indexOf("?") !== -1 ? '&' : '?'}txTransactionId=${txTransactionId}&referenceId=${referenceId}`
      redirectUrl = `${redirectUrl}${redirectUrl.indexOf("?") !== -1 ? '&' : '?'}${providerId ? 'providerId=' + providerId : ''}${connectionId ? '&connectionId=' + connectionId : ''}${this.codeIsError ? 'code=REQUEST_ERROR' : ''}`
      let tempALink = document.createElement("a")
      tempALink.setAttribute("target", "_top")
      tempALink.setAttribute("id", "openWin")
      tempALink.setAttribute("href", redirectUrl)
      if(!document.getElementById('openWin')) document.body.appendChild(tempALink);
      document.getElementById("openWin").click()
      document.body.removeChild(tempALink) 
    }
  },
  computed: {
    userToken() {
      return this.$route.query.token
    },
    txId() {
      return this.$route.query.transactionId || ''
    },
  },
}
</script>
<style lang="scss" scoped>
.employmentCheck_wrap{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  &.employmentCheck_dark_wrap{
    background: rgba(3, 28, 16, 0.76);
  }
  .employmentCheck_block_box{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99999999999;
    background: #ffffff;
  }
  .employmentCheck_popover_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
    background: #F3F6F6;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    cursor: pointer;
    .text-lg{
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .text-con{
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .font-bold{
    font-weight: bold;
  }
  .smile-modal-action{
    display: flex;
    margin-top: 1.5rem;
    justify-content: flex-end;
    .btn{
      display: inline-flex;
      background: #ffffff;
      color: #666666;
      padding: 8px 10px;
      transition-duration: .2s;
      border-radius: 6px;
      &:hover{
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
      }
    }
  }
}
@media screen and (max-width: 500px), screen and (max-aspect-ratio: 11 / 16) {
  .employmentCheck_wrap{
    .employmentCheck_popover_box{
      width: 90%;
    }
  }
}
</style>
