import esignH5 from '@/views/esign/h5/index.vue'
import esignH5Result from '@/views/esign/h5/result.vue'
import esignWeb from '@/views/esign/web/index.vue'
export const esignRouter = [
  {
    path: `/esign/h5/index`,
    name: 'esignH5',
    component: esignH5,
  },
  {
    path: `/esign/h5/result`,
    name: 'esignH5Result',
    component: esignH5Result,
  },
  {
    path: `/esign/web/index`,
    name: 'esignWeb',
    component: esignWeb,
  }
]