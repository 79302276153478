<template>
  <div class="sign-wrap">
    <sign-header></sign-header>
    <div class="sign-main">
      <div class="sign-box" ref="signBox">
        <sign-board
          ref="esign"
          v-if="isRender"
          :isCrop="isCrop"
          :height="height"
          :width="width"
          :lineWidth="lineWidth" 
          :lineColor="lineColor" 
          :bgColor.sync="bgColor"
          @drawChange="drawChange"
        />
      </div>
      <div class="sign-btns">
        <button :disabled="btnDisabled" @click="handleGenerate" :class="btnDisabled ? 'btn-disabled' : ''" :style="{background: themeColor}">
          {{$t('sign.save')}}
          <svg-icon icon-class="loading" class="loading" v-show="isDisabled"></svg-icon>
        </button>
        <button :disabled="btnDisabled" @click="handleReset" :class="btnDisabled ? 'btn-disabled' : ''" :style="{borderColor: themeColor, color: themeColor}">{{$t('sign.delete')}}</button> 
      </div>
    </div>
  </div>
</template>
<script>
import signHeader from '@/components/esign/header'
import signBoard from '@/components/esign/signBoard'
import { detection } from '@/api/modules/esign'
import { base64ToFile } from '@/lib/utils'
import { sentEventTrack, getReport } from '@/lib/eventTrack'
export default {
  data () {
    return {
      isRender: false,
      lineWidth: 3,
      lineColor: '#000',
      height: 300,
      width: 400,
      bgColor: '',
      resultImg: '',
      isCrop: true,
      isDraw: false,
      isDisabled: false
    }
  },
  components: {
    signHeader,
    signBoard
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.signBox.offsetHeight
      this.width = this.$refs.signBox.offsetWidth
      this.isRender = true
    })
  },
  computed: {
    btnDisabled() {
      return !this.signatureId || !this.isDraw || this.isDisabled
    },
    themeColor() {
      return sessionStorage.getItem('themeColor')
    },
    signatureId() {
      return this.$route.query.signatureId || ''
    }
  },
  methods: {
    drawChange(val) {
      this.isDraw = val
    },
    handleReset () {
      this.$refs.esign.reset()
    },
    handleGenerate () {
      this.$refs.esign.generate().then( async res => {
        this.isDisabled = true
        this.resultImg = base64ToFile(res)
        this.clickSentEventTrack(this.resultImg)
        const body = new FormData()
        body.append('image', this.resultImg, `esign${this.signatureId}.png`)
        body.append('signatureId', this.signatureId)
        const { data } = await detection(body, {
          headers: {'Content-Type': 'multipart/form-data'}
        })
        if (data.code !== 'SUCCESS') {
          this.isDisabled = false
          this.$router.push({
            name: 'esignH5Result',
            query: {
              themeColor: this.themeColor.slice(1),
              message: data.message,
              failReturnUrl: data.data && (data.data.failReturnUrl || this.$route.query.failReturnUrl) || '',
              tryCount: data.data && data.data.tryCount || '',
              result: data.code,
              bizToken: this.$route.query.bizToken
            }
          })
        } else {
          this.isDisabled = false
          this.successSentEventTrack(this.resultImg, data.data)
          this.$router.push({
            name: 'esignH5Result',
            query: {
              themeColor: this.themeColor.slice(1),
              failReturnUrl: data.data && (data.data.failReturnUrl || this.$route.query.failReturnUrl) || '',
              successReturnUrl: data.data.successReturnUrl || '',
              tryCount: data.data && data.data.tryCount || '',
              result: data.code,
              bizToken: this.$route.query.bizToken
            }
          })
        }
      }).catch(err => {
        this.isDisabled = false
        console.log(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    clickSentEventTrack(file) {
      const report = getReport()
      report.eventTimestamp = new Date().getTime()
      report.info.detail.imageOrigFormat = file.type
      report.info.detail.imageOrigSize = file.size
      sentEventTrack(report)
    },
    successSentEventTrack(file, data) {
      const report = getReport()
      report.eventTimestamp = new Date().getTime()
      report.info.detail = data
      report.info.detail.imageOrigFormat = file.type
      report.info.detail.imageOrigSize = file.size
      sentEventTrack(report)
    },
  }
}
</script>
<style lang="scss" scoped>
  .sign-wrap {
    height: 100%;
    .sign-main {
      padding: 0 .16rem .16rem;
      height: calc(100% - 1.4rem);
      display: flex;
      flex-direction: column;
    }
  }
  .sign-box {
    width: 100%;
    flex: 1;
    background: #F9F9F9;
    border-radius: .16rem;
  }
  .sign-btns {
    padding: .56rem .32rem .42rem;
    text-align: center;
    button{
      width: 100%;
      height: .84rem;
      background: #38a822;
      border: 0;
      border-radius: .12rem;
      color: #fff;
      font-size: .3rem;
      &.btn-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      &:last-child {
        margin-top: .2rem;
        background: none;
        border: 1px solid #38a822;
        color: #38a822
      }
      .loading {
        animation: rotating 2s linear infinite;
        background: none;
      }
      @keyframes rotating {
        0% {
          transform: rotate(0deg)
        }
        to {
          transform: rotate(1turn)
        }
      }
    }
  }
  
</style>