// import devicefinger from '@/views/devicefinger/index.vue'
export const devicefingerRouter = [
  {
    path: `/devicefinger/h5/index`,
    name: 'devicefinger',
    component: () => import('@/views/devicefinger/index.vue'),
    meta: {
			keepAlive: false		//缓存
		}
  },
  {
    path: `/devicefinger/h5/indexNextData`,
    name: 'indexNextData',
    component: () => import('@/views/devicefinger/indexNextData.vue')
  }
]