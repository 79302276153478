import axios from 'axios'
import qs from 'querystring'

// 接口前缀
const hostname = window.location.hostname
const pathname = window.location.pathname || ''
// const hostname = 'in-h5.advai.cn'
let country = ''
const hostnameSplitArr = hostname.split('-') || []
if (pathname.indexOf('/devicefinger') >= 0) {
  country = ''
} else if (hostnameSplitArr.length > 0) {
  let hostnameCountry = ''
  for (let i = 0; i < hostnameSplitArr.length; i++) {
    const hostnameItem = hostnameSplitArr[i]
    switch (hostnameItem ? hostnameItem : '') {
      // 越南
      case 'vn':
        hostnameCountry = 'vn'
        break
      // 菲律宾
      case 'ph':
        hostnameCountry = 'ph'
        break
      // 印度
      case 'in':
        hostnameCountry = 'in'
        break
      // 泰国
      case 'th':
        hostnameCountry = 'th'
        break
      // 马来
      case 'my':
        hostnameCountry = 'my'
        break
      // 印尼无前缀
      default:
        break
    }
    country = hostnameCountry
  }
}
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}/${country}`,
  timeout: 120000,
  paramsSerializer: params => {
    return qs.stringify(params)
  },
})
const requestInterceptor = (config) => {
  config.headers['X-ADVAI-TOKEN'] = sessionStorage.getItem('bizToken')
  if (navigator.language === 'id') {
    config.headers['Accept-Language'] = 'id-ID'
  }
  return config
}
const responseInterceptor = (response) => {
  return response
}

instance.interceptors.request.use(requestInterceptor)
instance.interceptors.response.use(responseInterceptor)


const post = (url, config) => {
  const data = config || {}
  console.log()
  return instance({
    url,
    method: 'POST',
    data: data
  })
}

const get = (url, params) => {
  return instance({
    url,
    method: 'GET',
    params: params
  })
}

export { post, get, instance }
